import { Header } from 'core/components/Header';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { AccountDashboard } from '../../components/Account';
import { forms } from '../../react_hook_form/constants/data';
import { useFormContext } from 'react-hook-form';
import { DashboardForm } from '../../react_hook_form/constants/type';
import { useOnClickOutside } from 'usehooks-ts';
import { Typography } from 'core/components/Typography';
import { useAuth0 } from '@auth0/auth0-react';

export interface NavbarDashboardProps {
  showProfile?: boolean;
}

export const NavbarDashboard = ({ showProfile = true }: NavbarDashboardProps) => {
  const { logout } = useAuth0();
  const location = useLocation();
  const { watch, setValue } = useFormContext<DashboardForm>();
  const navigate = useNavigate();
  const LOGO = '/logo/applied_ai_icon.svg';

  const ref = React.useRef<HTMLDivElement | null>(null);

  useOnClickOutside(ref, () => {
    setValue(forms.menu_dropdown.is_open, false);
  });
  const handleClick = () => {
    setValue(forms.menu_dropdown.is_open, !watch(forms.menu_dropdown.is_open));
  };

  const menu =
    (watch(forms.menu_dropdown.items) as
      | { name: string; icon: string; href: string; active: boolean }[]
      | undefined) ?? [];

  const handleClickNavigate = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.id.toLowerCase() === 'logout') {
      localStorage.clear;
      logout({
        logoutParams: {
          returnTo: process.env.REACT_APP_REDIRECT_LOGOUT_LINK
        }
      });
    }
    const link = e.currentTarget.value;

    const reLink = /^\/[^/]/;
    const isSlashLink = reLink.test(link);

    if (isSlashLink) return navigate(link);
    else return (window.location.href = link);
  };

  const isLogoAbleToRedirect =
    location.pathname === '/invoices' || location.pathname === '/account';

  const handleClickLogo = () => {
    if (isLogoAbleToRedirect) {
      window.location.href = process.env.REACT_APP_REDIRECT_CUSTOMER_LOGIN_LINK ?? '';
    }
  };
  return (
    <Header>
      <button
        className={clsx(isLogoAbleToRedirect ? 'cursor-pointer' : 'cursor-default')}
        onClick={handleClickLogo}>
        <img src={LOGO} alt={'logo'} className='max-h-[23px]' />
      </button>

      <div
        className={clsx(
          'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.75rem]'
        )}>
        {showProfile && (
          <div
            ref={ref}
            className={clsx(
              'grid grid-cols-1 place-content-start place-items-start gap-[0.5rem]',
              'relative'
            )}>
            <button onClick={handleClick}>
              <AccountDashboard
                image={watch(forms.navbar.image) as string | undefined}
                name={watch(forms.navbar.name) as string | undefined}
                email={watch(forms.navbar.email) as string | undefined}
                initial={watch(forms.navbar.initial) as string | undefined}
              />
            </button>

            {/* list */}
            {(!!watch(forms.menu_dropdown.is_open) as boolean) && (
              <div
                className={clsx(
                  'absolute top-[60px]',
                  'grid grid-cols-1 place-content-start place-items-start',
                  'w-full',
                  'px-[0.25rem] py-[0.25rem]',
                  'rounded-[0.25rem]',
                  'border border-[#F0F1F1]',
                  'z-10',
                  'bg-white'
                )}>
                {menu.map((item) => (
                  <button
                    key={item.name}
                    id={item.name}
                    className={clsx(
                      'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]',
                      'bg-white hover:bg-[#F8F8F8]',
                      'px-[0.5rem] py-[0.375rem]',
                      'w-full'
                    )}
                    value={item.href}
                    onClick={handleClickNavigate}>
                    {!!item.icon && (
                      <img src={item.icon} className={clsx('w-[1rem] h-[1rem]')} alt={item.name} />
                    )}
                    <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-90'}>
                      {item.name}
                    </Typography>
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </Header>
  );
};

import * as React from 'react';
import clsx from 'clsx';
import { Typography } from '../Typography';

export interface FieldProps extends React.HTMLProps<HTMLInputElement> {
  required?: boolean;
  mainLabel?: string;
  secondaryLabel?: string;
  helperText?: string;
  variant?: 'default' | 'error';
  fieldSize?: 'S' | 'M';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const Field = ({
  required = false,
  mainLabel = '',
  secondaryLabel = '',
  helperText = '',
  variant = 'default',
  fieldSize = 'M',
  startIcon,
  endIcon,
  onFocus,
  onBlur,
  disabled,
  ...otherProps
}: FieldProps) => {
  const [isFocus, setIsFocus] = React.useState<boolean>(false);

  const handleFocus = () => {
    setIsFocus((prev) => true);
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus((prev) => false);
    if (onBlur) {
      onBlur(event); 
    }
  };
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start gap-[0.5rem]',
        'w-full'
      )}>
      {(!!mainLabel.length || !!secondaryLabel.length) && (
        <div className={clsx('flex items-center justify-between', 'w-full')}>
          {!!mainLabel.length && (
            <Typography variant={'Regular'} size={'S'} color={'text-grayscale-70'}>
              {mainLabel} {required && ('*')}
            </Typography>
          )}

          {!!secondaryLabel.length && (
            <Typography variant={'Regular'} size={'S'} color={'text-grayscale-60'}>
              {secondaryLabel}
            </Typography>
          )}
        </div>
      )}

      <div
        className={clsx(
          'grid items-center content-center justify-start justify-items-start',
          typeof startIcon !== 'undefined' && typeof endIcon !== undefined
            ? 'grid-cols-[auto_repeat(1, minmax(0, 1fr))_auto]'
            : typeof startIcon !== 'undefined'
            ? 'grid-cols[auto_repeat(1, minmax(0, 1fr))]'
            : typeof endIcon !== 'undefined'
            ? 'grid-cols-[1fr_auto] justify-between gap-[0.5rem]'
            : 'grid-cols-1',

          'w-full',
          fieldSize === 'M' ? 'h-[3rem]' : 'h-[2.5rem]',
          'rounded-[0.375rem]',
          'border',
          disabled ? 'bg-grayscale-30' : 'bg-grayscale-10',
          variant === 'error'
            ? 'border-error-primary'
            : isFocus
            ? 'border-grayscale-90'
            : 'border-grayscale-50',
          fieldSize === 'M' ? 'px-[1rem] py-[0.875rem]' : 'px-[0.75rem] py-[0.75rem]'
        )}>
        {startIcon && startIcon}
        <input
          {...otherProps}
          data-testid={'input'}
          className={clsx(
            'outline-none',
            'w-full',
            'placeholder:text-[0.875rem] placeholder:text-grayscale-60 placeholder:leading-[146%] placeholder:tracking-[-0.14px] font-normal',
            'text-[0.875rem] text-grayscale-90 font-normal leading-[146%] tracking-[-0.14px]',
            'disabled:bg-grayscale-30 disabled:text-grayscale-60'
          )}
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          required={required}
        />
        {endIcon && endIcon}
      </div>

      {!!helperText.length && (
        <Typography
          variant={'Regular'}
          size={'XS'}
          color={variant === 'error' ? 'text-error-primary' : 'text-grayscale-60'}>
          {helperText}
        </Typography>
      )}
    </div>
  );
};

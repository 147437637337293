import { useQuery } from '@tanstack/react-query';
import { RegistrationReactQueryKey } from '../keys';
import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import React from 'react';
import { useRegistrationGetAccessTokenSilently } from './useGetAccessTokenSilently.registration';
import { useRegistrationGetIdTokenClaims } from './useGetIdTokenClaims.registration';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  GetUserSuccessResponseInterface,
  GetUserRequestInterface
} from 'core/models/api/registration';
import { fetchGetUser } from 'core/services/registration';
import { gql } from 'graphql-request';

export const useSystemAuth0 = () => {
  const [auth0TokenData, setAuth0TokenData] = useState<string | undefined>(undefined);
  const {
    isLoading: authLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    getIdTokenClaims,
    error,
    user
  } = useAuth0();

  const [isLoading, setLoading] = useState(true);

  const query = useQuery({
    queryKey: RegistrationReactQueryKey.GetAccessTokenSilently(),
    queryFn: async () => {
      try {
        const res = await getAccessTokenSilently();
        return res;
      } catch (err) {
        throw err;
      }
    },
    retry: 0,
    enabled: !authLoading
  });

  const mutation = useMutation<GetUserSuccessResponseInterface, AxiosError, IdToken>(
    RegistrationReactQueryKey.QueryGetUser(),
    () => {
      const payload: GetUserRequestInterface = {
        headers: {
          token: auth0TokenData
        },
        data: {
          query: gql`
            query {
              user(email: "${user?.email || ''}") {
                id,
                email,
                status,
                organization{
                  id
                }
              }
            }
          `
        }
      };

      return fetchGetUser(payload);
    },
    {
      retry: 0,
      onSuccess(data) {
        if (data.data.user !== null) {
          localStorage.setItem('userId', data.data.user.id);

          if (data.data.user.organization !== null) {
            localStorage.setItem('organizationId', data.data.user.organization?.id ?? '');
          }
        }
      }
    }
  );

  // Attempt to get the access token silently when not authenticated
  React.useEffect(() => {
    const checkAuthentication = async () => {
      if (!isAuthenticated && !authLoading) {
        try {
          await getAccessTokenSilently();
        } catch (e) {
          // If silent authentication fails, redirect to login
          loginWithRedirect();
        }
      }
    };

    checkAuthentication();
  }, [isAuthenticated, authLoading, getAccessTokenSilently, loginWithRedirect]);

  // Load the user from the backend
  useEffect(() => {
    if (query.data) {
      localStorage.setItem('token', query.data);

      getIdTokenClaims()
        .then((res) => {
          console.log(res);
          localStorage.setItem('idToken', res?.__raw ?? '');
          localStorage.setItem('auth0_user', JSON.stringify(user));

          setUserDetails(query.data);
          return res;
        })
        .catch((err) => {
          throw err;
        });
      setLoading(false);
    }
  }, [query.data]);

  async function setUserDetails(auth0Token: string) {
    const payload: GetUserRequestInterface = {
      headers: {
        token: auth0Token
      },
      data: {
        query: gql`
          query {
            user(email: "${user?.email || ''}") {
              id,
              email,
              status,
              organization{
                id
              }
            }
          }
        `
      }
    };

    const results = await fetchGetUser(payload);

    if (results.data.user !== null) {
      localStorage.setItem('userId', results.data.user?.id);

      if (results.data.user.organization !== null) {
        localStorage.setItem('organizationId', results.data.user.organization?.id ?? '');
      }
    }
  }

  return {
    isAuthenticated,
    isLoading,
    error
  };
};

import clsx from 'clsx';
import { useSystemAuth0 } from 'features/registration/customer/react_query/hooks/useSystemAuth0';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const withAuth = (WrappedComponent: React.FC) => {
  const WithLoginWrapper = () => {
    const { isAuthenticated, isLoading, error } = useSystemAuth0();

    if (isLoading) {
      return (
        <div className="flex items-center justify-center w-full h-screen">
          <img
            className="w-32 h-32"
            src="https://cdn.auth0.com/blog/hello-auth0/loader.svg"
            alt="Loading..."
          />
        </div>
      );
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    }

    if (!isAuthenticated) {
      // The user is being redirected to the login page
      return null;
    }

    return <WrappedComponent />;
  };

  return WithLoginWrapper;
};

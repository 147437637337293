import * as React from 'react';
import clsx from 'clsx';
import CustomerHomeData from 'features/home/customer/data/customer.home.json';
import { Typography } from 'core/components/Typography';
import { useAuth0 } from '@auth0/auth0-react';

export const HeroCustomerHome = () => {
  const { loginWithRedirect } = useAuth0();

  const redirectURI = process.env.REACT_APP_AUTH0_REDIRECT_URI_CUSTOMER;

  const handleClickApply = () => {
    loginWithRedirect({
      appState: {
        returnTo: 'account'
      }
    });
  };

  const handleClickRegister = () => {
    loginWithRedirect({
      authorizationParams: {
        login_hint: 'register_form'
      },
      appState: {
        returnTo: redirectURI
      }
    });
  };

  return (
    <div
      className={clsx(
        'grid grid-cols-1 items-center content-center justify-center justify-items-center',
        'w-full',
        'bg-grayscale-20',
        'px-[4rem] py-[6rem]',
        'rounded-bl-[1rem] rounded-br-[1rem]'
      )}>
      <div
        className={clsx(
          'grid grid-cols-1 lg:grid-cols-2 items-start content-start justify-center justify-items-center',
          'w-full max-w-[1200px]'
        )}>
        <div
          className={clsx(
            'grid grid-cols-1 items-center content-center justify-start justify-items-start gap-[2.5rem]',
            'w-full h-full'
          )}>
          <div
            className={clsx(
              'grid grid-cols-1 items-center content-center justify-start justify-items-start gap-[2rem]',
              'w-full h-full'
            )}>
            <h1
              className={clsx(
                'text-[68px] font-medium font-eliza text-grayscale-90 leading-[100%] tracking-[-0.68px]'
              )}
              dangerouslySetInnerHTML={{ __html: CustomerHomeData.hero.message }}
            />

            <div className={clsx('w-full max-w-[300px]')}>
              <Typography variant={'Regular'} size={'L'} color={'text-grayscale-90'}>
                {CustomerHomeData.hero.description}
              </Typography>
            </div>
          </div>

          <div className="flex items-center gap-x-4">
            <button
              className={clsx(
                'rounded-[0.375rem]',
                'px-[2rem] py-[1rem]',
                'bg-grayscale-black',
                'flex items-center justify-center',
                'text-[1rem] text-grayscale-10 font-medium leading-[150%] tracking-[-0.32px]'
              )}
              onClick={handleClickRegister}>
              {CustomerHomeData.hero.actions.primary}
            </button>
            <button
              className={clsx(
                'rounded-[0.375rem]',
                'px-[3rem] py-[1rem]',
                'bg-cta-mint-primary',
                'flex items-center justify-center',
                'text-[1rem] text-grayscale-black font-medium leading-[150%] tracking-[-0.32px]'
              )}
              onClick={handleClickApply}>
              {CustomerHomeData.hero.actions.secondary}
            </button>
          </div>

          <div
            className={clsx(
              'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[3rem]'
            )}>
            {CustomerHomeData.hero.statistic.items.map((item) => (
              <div
                key={item.label}
                className={clsx(
                  'grid grid-cols-1 items-start content-start justify-start justify-items-start'
                )}>
                <h4
                  className={clsx(
                    'font-eliza font-medium text-[38px] text-grayscale-black leading-[110%] tracking-[-0.38px]'
                  )}>
                  {item.value}
                </h4>
                <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-90'}>
                  {item.label}
                </Typography>
              </div>
            ))}
          </div>
        </div>

        <div>
          <img
            src={CustomerHomeData.hero.image}
            className={clsx('max-w-[518px] w-full')}
            alt={CustomerHomeData.hero.image}
          />
        </div>
      </div>
    </div>
  );
};

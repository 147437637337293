import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import * as React from 'react';
import RegistrationData from '../../data/registration.json';
import { Field } from 'core/components/Field';
import { useFormContext, FieldError } from 'react-hook-form';
import { Button } from 'core/components/Button';
import { RoleSelectFieldRegistration } from 'features/registration/customer/components/role_select';
import { TextButton } from 'core/components/TextButton';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/20/solid';
import { Alert } from 'core/components/Alert';
import { RegistrationForm } from 'features/registration/customer/react_hook_form/constants/type';
import { forms } from 'features/registration/customer/react_hook_form/constants/data';
import { useRegistrationMutationInviteUsers } from '../../react_query/hooks/useMutationInviteUsers.registration';
import { useAuth0 } from '@auth0/auth0-react';
import { REGEX } from 'core/utils/regex';

type TeamMembersErrors = {
  [key: `email-${number}`]: FieldError | undefined;
};

type ExtendedRegistrationForm = RegistrationForm & {
  team_members_add_team_members_registration: TeamMembersErrors;
};

type EmailErrors = {
  [key: string]: { message: string }
};

export const AddTeamMembersRegistration = () => {
  const { user } = useAuth0();
  const { mutate: inviteUsers } = useRegistrationMutationInviteUsers();
  const {
    watch,
    formState,
    setValue,
    setError,
    clearErrors
  } = useFormContext<RegistrationForm>();

  const errors = formState.errors as unknown as ExtendedRegistrationForm;
  const emailErrors = (errors['team_members-add_team_members-registration'] as unknown as EmailErrors) || {};

  const invitedUsers = watch(forms.add_team_members.team_members) as {
    email: string;
    roles: { id: string; name: string } | null;
  }[];

  const handleSelectRole = (value: { index: number; data: { id: string; name: string } }) => {
    const payload = invitedUsers.map((invitedUser, index) => {
      return {
        ...invitedUser,
        roles: index === value.index ? value.data : invitedUser.roles
      };
    });
    setValue(forms.add_team_members.team_members, payload);
  };

  const handleChangeTeamMemberName = (value: { index: number; data: string }) => {
    if (!value.data || !REGEX.EMAIL.test(value.data)) {
      setError(`${forms.add_team_members.team_members}.email-${value.index}` as keyof RegistrationForm,{
        message: RegistrationData.workspace.add_team_members.forms.errors.invalid_email,
      });
    } else {
      clearErrors(`${forms.add_team_members.team_members}.email-${value.index}` as keyof RegistrationForm);
    }

    const payload = invitedUsers.map((invitedUser, index) => {
      return {
        ...invitedUser,
        email: index === value.index ? value.data : invitedUser.email
      };
    });
    setValue(forms.add_team_members.team_members, payload);
  };

  const handleClickAddAnother = () => {
    setValue(forms.add_team_members.team_members, [
      ...invitedUsers,
      { email: '', roles: { id: 'viewer', name: 'Viewer' } }
    ]);
  };

  const handleRemoveAnother = () => {
    if (invitedUsers.length > 1) {
      setValue(forms.add_team_members.team_members, invitedUsers.slice(0, -1));
    }
  };

  const handleClickAdd = () => {
    const invitedUsers = watch(forms.add_team_members.team_members) as {
      email: string;
      roles: { id: string; name: string } | null;
    }[];
    
    // Filter out empty and duplicate emails
    const uniqueEmails = new Set();
    const filteredInvitedUsers = invitedUsers.filter(user => {
      const isValidEmail = user.email.trim() !== "" && !uniqueEmails.has(user.email);
      if (isValidEmail) uniqueEmails.add(user.email);
      return isValidEmail;
    });

    setValue(forms.add_team_members.team_members, filteredInvitedUsers);

    // Validate emails and show error if invalid
    let hasInvalidEmail = false;
    filteredInvitedUsers.forEach((user, index) => {
      if (!REGEX.EMAIL.test(user.email)) {
        setError(
          `${forms.add_team_members.team_members}.email-${index}` as keyof RegistrationForm,
          { message: RegistrationData.workspace.add_team_members.forms.errors.invalid_email }
        );
        hasInvalidEmail = true;
      } else {
        clearErrors(`${forms.add_team_members.team_members}.email-${index}` as keyof RegistrationForm);
      }
    });

    // If there are invalid emails, stop the function here
    if (hasInvalidEmail) return;
  
    if (filteredInvitedUsers.length === 1 && !filteredInvitedUsers[0].email.length) {
      if (user?.email_verified) {
        window.location.href = `${process.env.REACT_APP_REDIRECT_CUSTOMER_REGISTRATION_LINK}`;
      } else {
        setValue(forms.user.is_email_verified, false);
      }
    } else {
      inviteUsers();
    }
  };

  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-items-center place-content-center gap-[2.5rem]',
        'w-[350px]'
      )}>
      <Alert
        isOpen={watch(forms.add_team_members.alert) as boolean}
        message={watch(forms.add_team_members.alert_message) as string}
      />
      <div
        className={clsx(
          'grid grid-cols-1 place-items-center place-content-center gap-[0.25rem]',
          'w-full'
        )}>
        <Typography variant={'Heading'} size={'H1'} color={'text-grayscale-90'}>
          {RegistrationData.workspace.add_team_members.title}
        </Typography>
        <Typography variant={'Regular'} size={'M'} color={'text-grayscale-70'}>
          {RegistrationData.workspace.add_team_members.message}
        </Typography>
      </div>

      {/* form */}
      <div
        className={clsx(
          'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1.25rem] mb-[2.5rem]',
          'w-full'
        )}>
        {invitedUsers.map((invitedUser, index) => (
          <Field
            key={index}
            mainLabel={RegistrationData.workspace.add_team_members.forms.team_members.mainLabel}
            name={`team_members-add_team_members-registration.email-${index}`}
            secondaryLabel={
              RegistrationData.workspace.add_team_members.forms.team_members.secondaryLabel
            }
            placeholder={RegistrationData.workspace.add_team_members.forms.team_members.placeholder}
            variant={
              emailErrors[`email-${index}`]?.message ? 'error' : 'default'
            }
            value={invitedUser.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeTeamMemberName({ index: index, data: e.currentTarget.value })
            }
            helperText={
              emailErrors[`email-${index}`]?.message || ''
            }
            endIcon={
              <div className={clsx('w-[128px]')}>
                <RoleSelectFieldRegistration
                  selected={invitedUser.roles}
                  options={RegistrationData.workspace.add_team_members.forms.team_members.options}
                  onSelect={(data: { id: string; name: string }) =>
                    handleSelectRole({ index: index, data: data })
                  }
                />
              </div>
            }
          />
        ))}

        <div className={clsx('w-full flex justify-between')}>
          <TextButton onClick={handleClickAddAnother}>
            <div
              className={clsx(
                'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]',
                'w-full'
              )}>
              <PlusCircleIcon className={clsx('w-[1rem] h-[1rem]', 'text-cta-purple-primary')} />
              <Typography variant={'Button'} size={'S'} color={'text-grayscale-90'}>
                {RegistrationData.workspace.add_team_members.forms.actions.primary}
              </Typography>
            </div>
          </TextButton>
          {invitedUsers.length > 1 && 
            <TextButton onClick={handleRemoveAnother}>
              <div
                className={clsx(
                  'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]',
                  'w-full'
                )}>
                <MinusCircleIcon className={clsx('w-[1rem] h-[1rem] ', 'text-red-600')} />
                <Typography variant={'Button'} size={'S'} color={'text-grayscale-90'}>
                  {RegistrationData.workspace.add_team_members.forms.actions.remove}
                </Typography>
              </div>
            </TextButton>
          }
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
            'w-full'
          )}>
          <Button variant={'primary'} fullWidth onClick={handleClickAdd}>
            {RegistrationData.workspace.add_team_members.actions.primary}
          </Button>
        </div>
      </div>
      {/* end form */}
    </div>
  );
};

import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import * as React from 'react';
import RegistrationData from '../../data/registration.json';
import { useFormContext } from 'react-hook-form';
import { Button } from 'core/components/Button';
import { SelectField } from 'core/components/SelectField';
import { Textarea } from 'core/components/Textarea';
import { RegistrationForm } from 'features/registration/customer/react_hook_form/constants/type';
import { forms } from 'features/registration/customer/react_hook_form/constants/data';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useRegistrationMutationCreateOrganization } from '../../react_query/hooks/useMutationCreateOrganization.registration';

export const AddMoreInformationRegistration = () => {
  const { mutate: createOrganization } = useRegistrationMutationCreateOrganization();
  const {
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors
  } = useFormContext<RegistrationForm>();

  const handleSelectIndustry = (data: { id: string; name: string }) => {
    setValue(forms.add_more_information.industry, data);
    if (!data) {
      setError(forms.add_more_information.industry, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.add_more_information.industry);
    }
  };

  const handleSelectTeamSize = (data: { id: string; name: string }) => {
    setValue(forms.add_more_information.team_size, data);
    if (!data) {
      setError(forms.add_more_information.team_size, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.add_more_information.team_size);
    }
  };

  const handleChangeAbout = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(forms.add_more_information.about, e.currentTarget.value);
  };

  const handleSelectError = (target: HTMLButtonElement,field: keyof typeof forms.add_more_information) =>{
    if(target){
      setError(forms.add_more_information[field], {
        message: RegistrationData.workspace.add_more_information.forms.errors.required
      });
    }
  }

  const handleClickNext = () => {
    createOrganization();
    // setValue(forms.state.create_workspace_step, 'add_team_members');
  };

  const handleClickBack = () => {
    setValue(forms.state.create_workspace_step, 'create_workspace');
  };

  const isButtonDisabled = !(watch(forms.add_more_information.industry) as null | {
    id: string;
    name: string;
  });
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-items-center place-content-center gap-[2.5rem]',
        'w-[350px]'
      )}>
      <div
        className={clsx(
          'grid grid-cols-1 place-items-center place-content-center gap-[0.25rem]',
          'w-full'
        )}>
        <Typography variant={'Heading'} size={'H1'} color={'text-grayscale-90'}>
          {RegistrationData.workspace.add_more_information.title}
        </Typography>
        <Typography variant={'Regular'} size={'M'} color={'text-grayscale-70'}>
          {RegistrationData.workspace.add_more_information.message}
        </Typography>
      </div>

      {/* form */}
      <div
        className={clsx(
          'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1.25rem]',
          'w-full'
        )}>
        <SelectField
          key={RegistrationData.workspace.add_more_information.forms.industry.name}
          mainLabel={RegistrationData.workspace.add_more_information.forms.industry.mainLabel}
          required={RegistrationData.workspace.add_more_information.forms.industry.required}
          secondaryLabel={
            RegistrationData.workspace.add_more_information.forms.industry.secondaryLabel
          }
          options={RegistrationData.workspace.add_more_information.forms.industry.options}
          placeholder={RegistrationData.workspace.add_more_information.forms.industry.placeholder}
          variant={!!errors[forms.add_more_information.industry] ? 'error' : 'default'}
          selected={
            watch(forms.add_more_information.industry) as null | { id: string; name: string }
          }
          onSelect={handleSelectIndustry}
          onError={(e) => {handleSelectError(e.target,'industry')}}
          helperText={errors[forms.add_more_information.industry]?.message as string}
        />

        <SelectField
          key={RegistrationData.workspace.add_more_information.forms.team_size.name}
          mainLabel={RegistrationData.workspace.add_more_information.forms.team_size.mainLabel}
          required={RegistrationData.workspace.add_more_information.forms.team_size.required}
          secondaryLabel={
            RegistrationData.workspace.add_more_information.forms.team_size.secondaryLabel
          }
          options={RegistrationData.workspace.add_more_information.forms.team_size.options}
          placeholder={RegistrationData.workspace.add_more_information.forms.team_size.placeholder}
          variant={!!errors[forms.add_more_information.team_size] ? 'error' : 'default'}
          selected={
            watch(forms.add_more_information.team_size) as null | { id: string; name: string }
          }
          onSelect={handleSelectTeamSize}
          onError={(e) => {handleSelectError(e.target,'team_size')}}
          helperText={errors[forms.add_more_information.team_size]?.message as string}
        />

        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1rem]',
            'w-full'
          )}>
          {/* about_company */}
          <Textarea
            key={RegistrationData.workspace.add_more_information.forms.about_company.name}
            mainLabel={
              RegistrationData.workspace.add_more_information.forms.about_company.mainLabel
            }
            name={RegistrationData.workspace.add_more_information.forms.about_company.name}
            secondaryLabel={
              RegistrationData.workspace.add_more_information.forms.about_company.secondaryLabel
            }
            placeholder={
              RegistrationData.workspace.add_more_information.forms.about_company.placeholder
            }
            variant={!!errors[forms.add_more_information.about] ? 'error' : 'default'}
            value={watch(forms.add_more_information.about) as string}
            onChange={handleChangeAbout}
            helperText={errors[forms.add_more_information.about]?.message as string}
          />
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
            'w-full'
          )}>
          <Button
            disabled={isButtonDisabled}
            variant={'primary'}
            fullWidth
            onClick={handleClickNext}>
            {RegistrationData.workspace.add_more_information.actions.primary}
          </Button>
          <button
            className={clsx(
              'grid grid-flow-col items-center content-center justify-center justify-items-center gap-[0.5rem]',
              'w-full',
              'text-[0.875rem] text-grayscale-70 font-medium leading-[146%] tracking-[-0.14px]'
            )}
            onClick={handleClickBack}>
            <ArrowLeftIcon className={clsx('text-[1rem] text-grayscale-70')} />
            {RegistrationData.workspace.add_more_information.actions.secondary}
          </button>
        </div>
      </div>
      {/* end form */}
    </div>
  );
};
